import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

const colors = {
  white: '#FFFFFF',
  antiFlashWhite: '#F2F3F5',
  platinum: '#E5E4E2',
  grey: '#E5E7EB',
  MikadoYellow: '#FFC542',
  brightOrange: '#FF5A1F',
  kellyGreen: '#1FB800',
  Carmine: '#931F1D',
  mediumBlue: '#015CA8',
  darkSlateGray: '#1F2D3D',
  darkGrey: '#565A6E',
  darkBlue: '#0D2240',
  lightBlue: '#00BFFF',
};

export const myTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: colors.mediumBlue,
        contrastText: colors.white,
      },
      secondary: {
        main: colors.mediumBlue,
        contrastText: colors.darkBlue,
      },
      error: {
        main: colors.Carmine,
        contrastText: colors.white,
      },
      warning: {
        main: colors.MikadoYellow,
        contrastText: colors.darkBlue,
      },
      success: {
        main: colors.kellyGreen,
        contrastText: colors.brightOrange,
      },
      background: {
        default: colors.grey,
        paper: colors.white,
      },
      text: {
        primary: colors.darkBlue,
        secondary: colors.darkGrey,
      },
      banner: {
        info: colors.MikadoYellow,
        error: colors.Carmine,
        text: colors.white,
        link: colors.antiFlashWhite,
      },
      navigation: {
        background: colors.mediumBlue,
        indicator: colors.platinum,
        color: colors.white,
        selectedColor: colors.white,
        navItem: {
          hoverBackground: colors.darkBlue,
        },
      },
    },
  }),
  fontFamily: 'Poppins, Arial, sans-serif',
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({
      colors: [colors.mediumBlue, colors.platinum],
      shape: shapes.wave,
      options: {
        fontColor: colors.white,
      },
    }),
    apis: genPageTheme({
      colors: [colors.mediumBlue, colors.platinum],
      shape: shapes.wave,
      options: {
        fontColor: colors.white,
      },
    }),
    documentation: genPageTheme({
      colors: [colors.mediumBlue, colors.platinum],
      shape: shapes.wave2,
    }),
    create: genPageTheme({
      colors: [colors.mediumBlue, colors.platinum],
      shape: shapes.round,
    }),
    other: genPageTheme({
      colors: [colors.mediumBlue, colors.platinum],
      shape: shapes.wave,
    }),
    app: genPageTheme({
      colors: [colors.mediumBlue, colors.platinum],
      shape: shapes.wave,
    }),
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.darkBlue,
          color: colors.white,
          boxShadow: 'none',
          borderBottom: `4px solid ${colors.darkBlue}`,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '80px',
          padding: '0 24px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: colors.darkBlue,
          color: colors.white,
          '&:hover': {
            backgroundColor: colors.mediumBlue,
          },
        },
        containedSecondary: {
          backgroundColor: colors.lightBlue,
          color: colors.white,
          '&:hover': {
            backgroundColor: colors.darkBlue,
          },
        },
        outlinedPrimary: {
          borderColor: colors.darkBlue,
          color: colors.darkBlue,
          '&:hover': {
            borderColor: colors.mediumBlue,
            backgroundColor: 'rgba(13, 34, 64, 0.04)',
          },
        },
        outlinedSecondary: {
          borderColor: colors.lightBlue,
          color: colors.lightBlue,
          '&:hover': {
            borderColor: '#0099CC',
            backgroundColor: 'rgba(0, 191, 255, 0.04)',
          },
        },
        root: {
          borderRadius: '8px',
          padding: '8px 16px',
          textTransform: 'uppercase',
          fontWeight: 'bold',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #E0E0E0',
          padding: '8px',
          transition: 'box-shadow 0.3s ease',
          '&:hover': {
            boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
          },
          color: colors.darkBlue,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '8px',
          justifyContent: 'center',
          '& .MuiButton-root': {
            color: colors.darkBlue,
            borderColor: colors.lightBlue,
          },
        },
      },
    },
  },
});
